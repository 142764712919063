<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="kt-portlet">
        <div class="kt-portlet__body kt-portlet__body--fit">
          <div
            class="kt-grid kt-wizard-v3 kt-wizard-v3--white"
            id="kt_wizard_v3"
            data-ktwizard-state="step-first"
          >
            <div class="kt-grid__item">
              <!--begin: Form Wizard Nav -->
              <div class="kt-wizard-v3__nav">
                <div
                  class="kt-wizard-v3__nav-items kt-wizard-v3__nav-items--clickable"
                >
                  <!--doc: Replace A tag with SPAN tag to disable the step link click -->
                  <div
                    class="kt-wizard-v3__nav-item"
                    data-ktwizard-type="step"
                    data-ktwizard-state="current"
                  >
                    <div class="kt-wizard-v3__nav-body">
                      <div class="kt-wizard-v3__nav-label">
                        <span>
                          <i class="flaticon2-folder"></i>
                        </span>
                        Principal
                      </div>
                      <div class="kt-wizard-v3__nav-bar"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Nav -->
            </div>
            <div
              class="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper"
            >
              <!--begin: Form Wizard Form-->
              <form class="kt-form" id="kt_form">
                <!--begin: Principal-->
                <div>
                  <div class="kt-heading kt-heading--md">
                    Work Order {{ WONumber }}
                  </div>
                  <div class="kt-form__section kt-form__section--first">
                    <div class="kt-wizard-v2__form">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-autocomplete
                              placeholder="Select Handler"
                              :items="handlerList"
                              item-text="name"
                              item-value="id"
                              label="Handler"
                              v-model="editedItem.handler"
                              :disabled="blockHanlderList"
                            ></v-autocomplete>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-menu
                              v-model="popDatepick0"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  label="Date"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  required
                                  v-model="editedItem.date"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.date"
                                @input="popDatepick0 = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-menu
                              v-model="popDatepick1"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  label="Date of Claim"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  v-model="editedItem.claim_date"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.claim_date"
                                @input="popDatepick1 = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Port"
                              name="port_label"
                              type="text"
                              v-model="editedItem.port_label"
                              ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Vessel"
                              name="vessel"
                              type="text"
                              required
                              v-model="editedItem.vessel"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Ex Vessel"
                              name="ex_vessel"
                              type="text"
                              v-model="editedItem.exvessel"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Voyage"
                              name="voyage"
                              type="text"
                              v-model="editedItem.voyage"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Ex Voyage"
                              name="ex_oyage"
                              type="text"
                              v-model="editedItem.exvoyage"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <v-textarea
                              autocomplete="Description"
                              label="Description"
                              rows="5"
                              required
                              v-model="editedItem.description"
                            ></v-textarea>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-autocomplete
                              placeholder="Select Agent"
                              :items="agentList"
                              item-text="name"
                              item-value="id"
                              label="Agent"
                              v-model="editedItem.agent"
                            ></v-autocomplete>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-autocomplete
                              placeholder="Select Client"
                              :items="clientList"
                              item-text="name"
                              item-value="id"
                              label="Client"
                              v-model="editedItem.client"
                            ></v-autocomplete>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Client Reference"
                              name="client_reference"
                              type="text"
                              v-model="editedItem.client_reference"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-autocomplete
                              :items="[
                                'Owner',
                                'Charter',
                                'Disponer Owner',
                                'Time Charter',
                                'Voyage Charter',
                                'Cargo Interests',
                              ]"
                              label="Legal Status"
                              v-model="editedItem.legal_status"
                            ></v-autocomplete>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Undewritters"
                              name="undewritters"
                              type="text"
                              v-model="editedItem.undewritters"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="form-group">
                            <v-select
                              value="Argentina"
                              label="Country"
                              :items="[
                                'Argentina',
                                'Uruguay',
                                'Paraguay',
                                'Brasil',
                              ]"
                              clearable
                              v-model="editedItem.country"
                            ></v-select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <v-autocomplete
                              placeholder="Select Lawyer"
                              :items="lawyerList"
                              item-text="name"
                              item-value="id"
                              label="Lawyer"
                              v-model="editedItem.lawyer"
                            ></v-autocomplete>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <v-textarea
                              autocomplete="Billing Instructions"
                              label="Billing Instructions"
                              rows="5"
                              v-model="editedItem.billing_instructions"
                            ></v-textarea>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <v-textarea
                              autocomplete="Notes"
                              label="Notes"
                              rows="5"
                              v-model="editedItem.notes"
                            ></v-textarea>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-menu
                              v-model="popDatepick2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  label="Notice Date"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  v-model="editedItem.notice_date"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.notice_date"
                                @input="popDatepick2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-menu
                              v-model="popDatepick3"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  label="Closed Date"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  v-model="editedItem.closed_date"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.closed_date"
                                @input="popDatepick3 = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <v-switch
                            inset
                            label="Scanned"
                            v-model="editedItem.scanned"
                          ></v-switch>
                        </div>
                        <div class="col-md-3">
                          <v-switch
                            inset
                            label="Closed"
                            v-model="editedItem.closed"
                            color="red"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Principal-->

                <!--begin: Form Actions -->
                <div class="kt-form__actions">
                  <button
                    @click="deleteDialog()"
                    class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                    @click.stop.prevent="submit()"
                  >
                    Delete
                  </button>
                  <button
                    class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                    type="submit"
                    @click.stop.prevent="submit()"
                  >
                    Submit
                  </button>
                </div>
                <!--end: Form Actions -->
              </form>
              <!--end: Form Wizard Form-->
            </div>
          </div>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import KTUtil from "@/assets/js/util";
//import KTWizard from "@/assets/js/wizard";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import genericService from "./../../../services/generic.service";

export default {
  components: {},

  data: () => ({
    uri: "workorders",
    today: new Date().toISOString().substr(0, 10),
    popDatepick0: false,
    popDatepick1: false,
    popDatepick2: false,
    popDatepick3: false,
    date: "",
    claim_date: "",
    notice_date: "",
    closed_date: "",
    WONumber: "",
    handlerList: [],
    agentList: [],
    clientList: [],
    lawyerList: [],
    editedItem: {
      handler: "",
      date: "",
      claim_date: "",
      port_label: "",
      vessel: "",
      exvessel: "",
      voyage: "",
      exvoyage: "",
      description: "",
      agent: "",
      client: "",
      legal_status: "",
      undewritters: "",
      country: "",
      lawyer: "",
      billing_instructions: "",
      notes: "",
      notice_date: "",
      closed_date: "",
      scanned: "",
      closed: "",
      client_reference: "",
    },

    /* Fields */
    ports: ["Argentina", "Brasil", "Alemania", "China"],

    headersClaimants: [
      { text: "Work Order", value: "work_order" },
      { text: "Company Name", value: "company_name" },
      { text: "Claim", value: "claim" },
      { text: "Claim Amount", value: "claim_amount" },
      { text: "Amount", value: "amount" },
      { text: "Lou", value: "lou", sortable: true },
      { text: "Actions", value: "actions" },
    ],

    headersReports: [
      { text: "Nr", value: "number", sortable: false, filterable: true },
      { text: "Date", value: "date", sortable: false },
      { text: "Description", value: "description", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersLou: [
      { text: "Work Order", value: "work_order", sortable: false },
      { text: "Replaced", value: "replaced", sortable: true },
      { text: "Beneficiary", value: "beneficiary", sortable: false },
      { text: "Amount", value: "amount", sortable: false },
      { text: "Emitted", value: "emited", sortable: false },
      { text: "Cancelled", value: "cancelled", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersInvoices: [
      { text: "Vessel", value: "vessel", sortable: false, filterable: true },
      { text: "Date", value: "date", sortable: false },
      { text: "Invoice", value: "invoice", sortable: false, filterable: true },
      { text: "To", sortable: false, value: "to", filterable: true },
      { text: "Amount", value: "amount", sortable: false, filterable: true },
      { text: "Paid", value: "status", sortable: true, filterable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersExpenses: [
      { text: "Company Name", value: "company_name", filterable: true },
      { text: "Date", value: "date" },
      { text: "Nr", value: "number", filterable: true },
      { text: "Surveyor Att.", value: "surveyor_attendance", filterable: true },
      { text: "Paid", value: "paid" },
      { text: "Paid Date", value: "paid_date" },
      { text: "Total", value: "total", filterable: true },
      { text: "Actions", value: "actions" },
    ],

    headersQuotations: [
      { text: "Nr", sortable: false, value: "number", filterable: true },
      { text: "WO", value: "work_order", sortable: false, filterable: true },
      { text: "Vessel", value: "vessel", sortable: false, filterable: true },
      { text: "Voyage", value: "voyage", sortable: false, filterable: true },
      { text: "To", value: "to", sortable: false, filterable: true },
      { text: "O/B", value: "ob", sortable: false, filterable: true },
      { text: "Date", value: "date", sortable: false },
      { text: "Notes", value: "notes", sortable: false, filterable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersQuotationsExtras: [
      { text: "Description", value: "description", sortable: false },
      { text: "Place", value: "place", sortable: false },
      { text: "QTY", value: "qty", sortable: false },
      { text: "Unit Price", value: "unit_price", sortable: false },
      { text: "Discount", value: "discount", sortable: false },
      { text: "Subtotal", value: "subtotal", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersNominations: [
      { text: "Nr", sortable: false, value: "number", filterable: true },
      { text: "WO", value: "work_order", sortable: false, filterable: true },
      { text: "Vessel", value: "vessel", sortable: false, filterable: true },
      { text: "Voyage", value: "voyage", sortable: false, filterable: true },
      { text: "Client", value: "client", sortable: false, filterable: true },
      {
        text: "Client Reference",
        value: "client_reference",
        sortable: false,
        filterable: true,
      },
      {
        text: "Legal Status",
        value: "legal_status",
        sortable: false,
        filterable: true,
      },
      { text: "Date", value: "date", sortable: false },
      { text: "Notes", value: "notes", sortable: false, filterable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersNominationsExtras: [
      { text: "Description", value: "description", sortable: false },
      { text: "Place", value: "place", sortable: false },
      { text: "Agent", value: "agent", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersProformas: [
      { text: "Nr", value: "number", sortable: false, filterable: true },
      { text: "WO", value: "work_order", sortable: false, filterable: true },
      { text: "Vessel", value: "vessel", sortable: false, filterable: true },
      { text: "Voyage", value: "voyage", sortable: false, filterable: true },
      { text: "To", value: "to", sortable: false, filterable: true },
      { text: "O/B", value: "ob", sortable: false, filterable: true },
      { text: "Date", value: "date", sortable: false },
      { text: "Notes", value: "notes", sortable: false, filterable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersProformasExtras: [
      { text: "Description", value: "description", sortable: false },
      { text: "Place", value: "place", sortable: false },
      { text: "QTY", value: "qty", sortable: false },
      { text: "Unit Price", value: "unit_price", sortable: false },
      { text: "Discount", value: "discount", sortable: false },
      { text: "Subtotal", value: "subtotal", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersBL: [
      { text: "Nr", value: "number", sortable: true, filterable: true },
      { text: "Port", value: "port", sortable: false },
      { text: "CNTR", value: "cntr", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersSurveyorsAttendance: [
      { text: "Nr", value: "number", sortable: true, filterable: true },
      {
        text: "Work Order",
        value: "work_order",
        sortable: false,
        filterable: true,
      },
      { text: "Date", value: "date", sortable: false },
      { text: "Vessel", value: "vessel", sortable: false },
      { text: "Voyage", value: "voyage", sortable: false },
      { text: "Aproval", value: "aproval", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersTime: [
      { text: "Date", value: "date", sortable: false },
      {
        text: "Work Order",
        value: "work_order",
        sortable: false,
        filterable: true,
      },
      { text: "Vessel", value: "vessel", sortable: false, filterable: true },
      {
        text: "Description",
        value: "description",
        sortable: false,
        filterable: true,
      },
      {
        text: "Handler",
        align: "start",
        sortable: false,
        value: "handler",
        filterable: true,
      },
      { text: "Time", value: "time", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    itemsTime: [],
    itemsClaimants: [],
    itemsReports: [],
    itemsLou: [],
    itemsExpenses: [],
    itemsInvoices: [],
    itemsQuotations: [],
    itemsQuotationsExtras: [],
    itemsNominations: [],
    itemsNominationsExtras: [],
    itemsProformas: [],
    itemsProformasExtras: [],
    itemsBL: [],
    itemsSurveyorsAttendance: [],
    blockHanlderList: false,
  }),

  mounted() {
    var _self = this;

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Work Orders", route: "/work-orders" },
      { title: "WO" },
    ]);

    // Initialize form wizard
    //const wizard = new KTWizard("kt_wizard_v3", {
    //startStep: 1, // initial active step number
    //clickableSteps: true // allow step clicking
    //});

    // Validation before going to next page
    //wizard.on("beforeNext", function(/*wizardObj*/) {
    // validate the form and use below function to stop the wizard's step
    // wizardObj.stop();
    //});

    // Change event
    //wizard.on("change", function(/*wizardObj*/) {
    //setTimeout(function() {
    //KTUtil.scrollTop();
    //}, 500);
    //});

    genericService.getRecordList(
      "/list/users/" + this.currentUser.role + "/handler",
      function (records) {
        _self.handlerList = records;

        if (_self.currentUser.role == "Handler") {
          _self.editedItem.handler = _self.currentUser.id;
          _self.blockHanlderList = true;
        }
      }
    );

    genericService.getRecordList("/list/contacts/Agent", function (records) {
      _self.agentList = records;
    });

    genericService.getRecordList("/list/contacts/Client", function (records) {
      _self.clientList = records;
    });

    genericService.getRecordList("/list/contacts/Lawyer", function (records) {
      _self.lawyerList = records;
    });
  },

  created() {},

  methods: {
    submit() {
      var _self = this;

      if (
        this.editedItem.vessel == "" ||
        this.editedItem.handler == "" ||
        this.editedItem.date == "" ||
        this.editedItem.description == ""
      ) {
        Swal.fire({
          title: "",
          text: "Fields Vessel, Handler, Date and Description are mandatories.",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
        return;
      }

      genericService.createRecord(
        "/" + this.uri,
        this.editedItem,
        function (rta) {
          if (rta != undefined) {
            _self.WONumber = rta.id;
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });

            _self.$router.push({
              name: "work-order-detail",
              params: { id: _self.WONumber },
            });
          }
        }
      );
    },

    deleteDialog() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "Deleted!",
            text: "Your record has been deleted.",
            icon: "success",
          }).then((result) => {
            if (result.value) {
              this.$router.push("/work-orders");
            }
          });
        }
      });
    },

    WONumberHaveNumber() {
      return this.WONumber.length > 0;
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
